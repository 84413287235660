.board-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #fff;
  height: 100vh;
  font-family: 'Montserrat', sans-serif;  
  width: 100vw;
  padding-left: 200px;
}

.board-content {
  display: flex;
  height: 80%;
  overflow-x: auto;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .board-body {
    padding-left: 1rem;
  }
}

@media screen and (max-width: 576px) {
  .board-body {
    padding-left: 0;
  }

  .board-content {
    flex-direction: row;
    height: auto;
    gap: 20px;
  }
}
