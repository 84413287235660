@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.container {
  text-align: center;
  margin: 20px;
  width: 100%;
}

h1 {
  font-size: 24px;
  margin-bottom: 30px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}

h5 {
  font-size: 16px;
  margin-bottom: 30px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  padding-left: 100px;
  padding-right: 100px;
}

.problem-statement-input {
  width: 100%; 
  border-color: black;
  border-width: 2px;
  margin-bottom: 10px;
}

.input-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
}

.input-container-marketing {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
  gap: 30px
}

.input-container2 {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 2s ease-out, max-height 2s ease;
  display: flex; /* Set to flex to make inner items side by side */
  justify-content: space-between; /* Add space between the two child divs */
}

.input-container2-single {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 2s ease-out, max-height 2s ease;
  
}

.ai-response {
  margin-bottom: 10px;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  border: 2px solid #1c4bba;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 0 8px #1c4bba;
  max-height: 200px; /* Maximum height for the container */
  overflow-y: auto; /* Add vertical scroll when the content exceeds the maximum height */
  width: 48%; /* Set width to slightly less than half to account for any padding/margins */
}

.ai-response-single {
  margin-bottom: 10px;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  border: 2px solid #1c4bba;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 0 8px #1c4bba;
  max-height: 200px; /* Maximum height for the container */
  overflow-y: auto; /* Add vertical scroll when the content exceeds the maximum height */
}

.selected-items {
  margin-bottom: 10px;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  border: 2px solid #1c4bba;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 0 8px #1c4bba;
  max-height: 150px; /* Maximum height for the container */
  overflow-y: auto; /* Add vertical scroll when the content exceeds the maximum height */
  width: 48%; /* Set width to slightly less than half to account for any padding/margins */
  max-height: 200px; /* Maximum height for the container */
}


.ai-response .response-item {
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 5px;
  transition: background-color 0.3s;
  font-size: 14px;
  display: flex; /* Add this */
  justify-content: space-between; /* Add this */
  align-items: center;
  text-align: left;
}

.ai-response-single .response-item {
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 5px;
  transition: background-color 0.3s;
  font-size: 14px;
  display: flex; /* Add this */
  justify-content: space-between; /* Add this */
  align-items: center;
  text-align: left;
}

.problem-link {
  text-align: center;
  font-size: 12px;
  text-decoration: none;
  align-self: center;
  justify-self: center;
  display: block;
  margin-bottom: 10px; /* Add some margin at the top to separate it from the input field */
}

.ai-response .response-item:hover {
  background-color: lightblue;
}

.ai-response .response-item.selected {
  background-color: lightskyblue;
}

.ai-response-single .response-item:hover {
  background-color: lightblue;
}

.ai-response-single .response-item.selected {
  background-color: lightskyblue;
}

input {
  padding: 10px 10px 10px 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
  width: 500px;

}

.delete-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #1c4bba;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
}

.generate-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #1c4bba;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
}

.hint {
  text-align: center;
  font-size: 14px;
  color:cornflowerblue;
  margin-bottom: 5px;
}

.selected-items .selected-item {
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 5px;
  transition: background-color 0.3s;
  font-size: 14px;
  display: flex; /* Add this */
  justify-content: space-between; /* Add this */
  align-items: center;
}

.sr-only {
  width: 20px;
}

.account {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 14px;
}

.input-with-icon {
  position: relative;
}

.input-with-icon input {
  padding-right: 30px; /* Adjust the value based on the size of the delete icon and desired spacing */
}


.show-problem-statement {
  opacity: 1;
  max-height: 500px;
}

h2 {
  font-size: 14px;
  margin-bottom: 10px;
  color: cornflowerblue;
  /* font-weight: bolder; */
}

.reset {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  font-size: 16px;
  background-color: #fff;
  color: #1c4bba;
  border: solid;
  border-radius: 4px;
  border-color: #1c4bba;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
}

.finalProblemStatementLabel {
  margin-bottom: 5px;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
}

.next-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #1c4bba;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
}

.back-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #fff;
  color: #1c4bba;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
}

.selected-item-input {
  overflow-y: hidden;  /* Hide vertical scrollbar */
  resize: none;        /* Prevent manual resize */
  width: 100%;         /* Take the full width */
  word-wrap: break-word; /* Ensure words wrap to next line */
  padding-left: 20px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
border-width: 2px;
border-radius: 4px;
}


@media screen and (max-width: 600px) {
  .container {
    margin: 5px;
  }

  input {
    width: 100%;
    margin-right: 10;
  }

  .reset, .next-button, .back-button {
    position: fixed; /* Change to fixed */
    bottom: 0; /* Set bottom to 0 */
    transform: none;
    margin: 20px 10px;
  }

  .back-button {
    left: 0; /* Position the reset button on the left */
  }

  .next-button {
    right: 0; /* Position the next button on the right */
  }

  .reset {
    left: 50%; /* Position the back button in the center */
    transform: translateX(-50%); /* Center the back button horizontally */
  }


  .generate-button {
    font-size: 12px;
    margin-left: 5px;
  }

  h5 {
    padding: 10px
    }

    .input-container2 {
      overflow: hidden;
      transition: opacity 2s ease-out, max-height 2s ease;
      display: flex;
    flex-direction: column; 
    }

    .ai-response {
      margin-bottom: 10px;
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      border: 2px solid #1c4bba;
      border-radius: 8px;
      padding: 10px;
      box-shadow: 0 0 8px #1c4bba;
      max-height: 200px; /* Maximum height for the container */
      overflow-y: auto; /* Add vertical scroll when the content exceeds the maximum height */
      width: 100%;
    }

    .selected-items {
      margin-bottom: 10px;
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      border: 2px solid #1c4bba;
      border-radius: 8px;
      padding: 10px;
      box-shadow: 0 0 8px #1c4bba;
      max-height: none;
            overflow-y: auto; /* Add vertical scroll when the content exceeds the maximum height */
      width: 100%;
    }

}
