@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.lof-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #fff;
  height: 100vh;
  font-family: 'Montserrat', sans-serif;  
  width: 100vw;
}

.table-rounded {
  border-radius: 8px;
}

.data-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  font-size: 14px;
  margin-bottom: 1rem;
  border-radius: 8px;
  border-style: solid;
  border-color: #dadee3;
  border-width: 1px;
  box-shadow: 0px 1px 1px 1px rgba(0,0,0,0.1);
}

.edit-button,
.feature-name-button {
  background-color: transparent;
  color: #111827;
  cursor: pointer;
  font-size: 14px;
}

.feature-list {
  width: 90%;
  border-radius: 10px;
  overflow: hidden; 
  border-style: solid;
  border-color: #000;
  border-width: 1px;
}

.feature-list th,
.feature-list td {
  padding: 10px 15px;  /* Adjust as needed */
  text-align: left;
  width: 400px;
}


.create-new-feature-btn {
  background-color: transparent;
  color: black;
  width: 18rem;
  text-align: left;
  font-size: 14px;
}

.lof-h2 {
  font-size: 2.5rem;
  font-family: 'Montserrat', sans-serif;
  word-break: break-word; /* To prevent long text from overflowing */
  margin: 1.25rem;
  color: black;
}

.delete-button {
  background-color: transparent;
  color: darkgrey;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure the modal is on top of other content */
}

.modal-content {
  width: 550px; /* or any desired width */
  padding: 20px;
  background-color: #ffffff; /* White background */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 10px;
}

/* Grid styles */
.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr); 
  gap: 20px;
  margin: 0px 20px;
}

/* Box styles */
.feature-box {
  border: 1px solid #e1e4e8;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}


.feature-logo {
  width: 50px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
  margin: 0 auto;
}

.feature-title {
  margin-top: 10px;
}

.connect-button {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #0366d6; /* Color of your choice */
  color: #ffffff;
  cursor: pointer;
}


@media screen and (max-width: 768px) {
  .lof-body {
    padding: 1rem;
  }

  .data-row {
    flex-direction: column;
  }

  .create-new-feature-btn {
    width: 100%;
  }

  .lof-h2 {
    font-size: 2rem;
  }

  .feature-list {
    width: 100%;
  }
}