.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  padding: 30px;
  align-self: center; 
  border-radius: 12px;
  width: 90%px;
  max-width: 90vw;
  overflow-y: auto;  /* Enable vertical scrolling if the content overflows */
  max-height: 90vh;  /* Or any height that fits your design */

}

.modal-content form {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center form fields horizontally in the form */
  width: 100%; /* Full width */
}

.modal-content label {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Center label contents horizontally in the label */
  margin-bottom: 20px;
  width: 100%; /* Full width of parent (form) */
}

.modal-message {
  align-self: flex-start; /* Center label contents horizontally in the label */
}

.modal-content input, .modal-content textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 12px;
  width: 90%; /* Fill 90% of the parent label width */
}

.modal-close {
  margin-top: 20px;
  align-self: flex-end; 
  background-color: #ffffff;
  color: black;
}

.modal-submit {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 90%; /* 90% of form width */
  align-self: flex-start;
}
