@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.content {
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: normal;
    overflow: auto;
    box-sizing: border-box;
}

.summary-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    gap: 10px;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    background: #ffffff;
    padding: 0px;
}

.navbar {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap; /* Allows the items to wrap in smaller screens */
}

.navbar-logo {
    height: 50px;
    flex-grow: 1; /* This will make the logo take the full width on smaller screens */
}

.navbar button {
    background-color:#fff;
    color: #000;
    border: none;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    font-family: 'Montserrat', sans-serif;
}

.navbar button:hover {
    color: #45b9d3;
}

.share-button {
    margin: 0px;
    font-size: 12px;
}

.download-button {
    color:cornflowerblue;
    background-color: #fff;
    border-color: cornflowerblue;
    border-style: solid;
    display: flex;
    align-items: center;
}

.download-button img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.copy-button {
    background-color: #fbfbfbcb;
    color: #000;
    font-size: 10px;
}

.copy-success {
    color: green;
}

.modal-close {
    position: relative;
    right: 20px;
    top: 10px;
    background: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
}

.summary-container > div {
    background: #fbfbfb;
    padding: 20px;
    border-radius: 20px;
    margin-top: 0px;
}

.summary-container h1 {
    margin-top: 0px;
    font-size: 18px;
}

.summary-container p {
    font-size: 12px;
    margin-bottom: 20px;
    font-family: 'Montserrat', sans-serif;
}

.summary-container h2 {
    font-size: 18px;
    margin-bottom: 20px;
    font-family: 'Montserrat', sans-serif;
}

.content-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
    align-self: center;
    margin: 10px;
}

.content-container > div {
    box-sizing: border-box;
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    margin-top: 10px;
}

.header-container {
    display: flex;
    gap: 30px;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    margin-left: 30px;
}

.ul {
    font-size: 12px;
}

@media screen and (max-width: 600px) {
    .content-container {
        grid-template-columns: 1fr;
        justify-content: center; 
        align-items: center; 
        width: 90%; /* adjust to fit screen */
        margin: 0 auto; /* centralize the grid */
    }

    .header-container {
        display: flex;
        flex-direction: column;
    }

    .header-container h1 {
        width: 100%;
    }

    .modal-close {
        position: static;
        margin: 10px 0;
    }

    .navbar {
        flex-direction: column;
        align-items: center;
    }
  
    .navbar-logo {
        margin-bottom: 10px; /* Creates some space between the logo and the buttons when they are stacked */
    }

    .navbar button {
        width: 100%; /* Makes the buttons full width */
    }

    .summary-container > div, .content-container > div {
        padding: 10px; /* reduces padding */
    }

    .summary-container h2, .summary-container p {
        font-size: 16px; /* reduces font size */
        margin-bottom: 10px; /* reduces bottom margin */
    }
}
