@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.sidebar-logo {
  max-width: 70px;
  max-height: 70px;
  margin: 25px;
}

.top-links, .bottom-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;  /* Make this a relative container for absolute positioned children */
}

.sidebar-toggle-btn {
  justify-content: center;
  width: 80px;
}


.sidebar-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.MuiDrawer-paper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.MuiListItem-root {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px; 
}

.sidebar-item-icon-text-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row; 
  width: 100%;
}

.sidebar-item-icon {
  display: flex;
  justify-content: center;
  width: 50px;
  min-width: 50px;

}

.sidebar-item-text {
  flex-grow: 1;
  color: #111827;
  font-family: 'Montserrat', sans-serif;
  padding-left: 10px;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .MuiDrawer-paper {
    width: 52px; /* Adjust the width for mobile screens based on the collapsed state */
  }
  
  .sidebar-item-text {
    display: none; 
  }
  
  .sidebar-logo {
    margin: 10px;
  }
}
