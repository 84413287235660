@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

body {
  min-height: 100vh; /* Set body height to at least 100% of the viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.create-account-btn {
  text-decoration: none;
  border: black 2px solid;
  border-radius: 6px;
  padding: 10px;
  color: #000;
}

.sign-up-btn {
  margin-left: 10px;
  text-decoration: none;
  background-color: rgba(0,0,0,.8);
  border: rgba(0,0,0,.8) 2px solid;
  border-radius: 6px;
  padding: 10px;
  color: #fff;
}

.container1 {
  height: 80%; /* Sets the minimum height of the container to fill the viewport */
  width: 100%;
  display: flex; /* Use Flexbox */
  flex-direction: column; /* Stack the children vertically */
  justify-content: center; /* Center children vertically */
  align-items: center; /* Center children horizontally */
  padding-top: 20%;
  margin-bottom: 10%;
}

.container1b {
  height: 60%; /* Sets the minimum height of the container to fill the viewport */
  width: 100%;
  display: flex; /* Use Flexbox */
  flex-direction: column; /* Stack the children vertically */
  justify-content: center; /* Center children vertically */
  align-items: center; /* Center children horizontally */
  padding-bottom: 10%;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #f5f5f5; /* Or any color you want for the footer */
}

.footer-logo {
  height: 50px; /* Adjust based on your logo's size */
  margin-right: 1rem;
}

.footer-btn {
  background: none;
  color: black;
  border: none;
  text-decoration: none;
  font-size: 1rem;
  margin: 0 0.5rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s;
}

.footer-btn:hover {
  background-color: #e0e0e0;
}

.container2 {
  height: 100%; /* Sets the minimum height of the container to fill the viewport */
  width: 100%;
  display: flex; /* Use Flexbox */
  flex-direction: column; /* Stack the children vertically */
  justify-content:space-between; /* Center children vertically */
  align-items:center; /* Center children horizontally */
}

.container3 {
  height: 100%; /* Sets the minimum height of the container to fill the viewport */
  width: 100%;
  display: flex; /* Use Flexbox */
  flex-direction: column; /* Stack the children vertically */
  justify-content: center; /* Center children vertically */
  align-items: center; /* Center children horizontally */
}

.problem-section {
  display: flex; /* Use Flexbox */
  flex-direction: row;
  width: 100%;
  justify-content:space-around; /* Center children vertically */
  align-items: center; /* Center children horizontally */
  padding: 5% 5%;
  background-image: linear-gradient(90deg,#b7d9fb,#e4f3e1),url(https://global-uploads.webflow.com/60c0cec90f5782a19af55867/64b638bab2301cbb30435b4e_Product.svg)
}

.problem-section2 {
  display: flex; /* Use Flexbox */
  flex-direction: row;
  width: 100%;
  justify-content:space-around; /* Center children vertically */
  align-items: center; /* Center children horizontally */
  padding: 5% 5%;
  background-image: linear-gradient(90deg,#fdedcc,#fea1b5);
}

.problem-section3 {
  display: flex; /* Use Flexbox */
  flex-direction: row;
  width: 100%;
  justify-content:space-around; /* Center children vertically */
  align-items: center; /* Center children horizontally */
  padding: 5% 5%;
  background-image: linear-gradient(50deg,#a084f5,#ccd9fa);}

.problem-section4 {
  display: flex; /* Use Flexbox */
  flex-direction: row;
  width: 100%;
  justify-content:space-around; /* Center children vertically */
  align-items: center; /* Center children horizontally */
  padding: 5% 5%;
  background-color: #f5f5f5;}

.dashboard-btn {
  background-color:#000;
  color: white;
  border-radius: 12px;
  padding: 8px 12px;
  text-decoration: none;
}

.content-container {
  margin-bottom: 20px
}
.email-form {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 0px;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  position: fixed;
  width: 100%;
  min-height: 5rem;
  top: 0;
  z-index: 999;  /* To make sure the navbar stays on top */
}

.spinning-logo {
  height: 42px; /* Adjust the width to make the logo smaller */
    /* position: absolute; */
    /* top: 20px; */
    /* left: 25px; */
}

.btn-container {
  display: flex;
  /* justify-content: flex-start; changed from center */
  margin: 20px 0px;
  gap: 4px;
}

.title {
  font-size: 6.4vw;
  color: #000;
  margin-bottom: 0px;
  margin-top: 0px;
  font-family: 'Inter', sans-serif; /* Changed from Montserrat to Inter */
  filter: saturate(200%);
    text-shadow: 11px 11px 8px rgba(0,0,0,.2);
    background-image: linear-gradient(225deg,#9afe9d,#ccfbfe);
    border-radius: 10rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
}

.subtitle {
  font-size: 20px;
  color: #696969;
  margin-bottom: 20px;
  margin-top: 20px;
  font-family: 'Montserrat', sans-serif;
  justify-self: center;  
}

.header {
  font-size: 30px;
  color: #000;
  padding-top: 100px;
  margin-bottom: 70px;
  font-family: 'Montserrat', sans-serif;
  justify-self: center;
  
}

.right-icon {
  color: white;
  transition: color 0.3s ease;
}

.right-icon:hover {
  color: black;
}

h3 {
  font-size: 2rem;
  color: #000000;
  margin-bottom: 30px;
  font-family: 'Montserrat', sans-serif;
}

p {
  font-size: 16px;
  color: #000000;
    width: 70%;
  margin-bottom: 30px;
  font-family: 'Montserrat', sans-serif;
}

.h4 {
  font-size: 20px;
  color: #000000;
  margin-bottom: 30px;
  font-family: 'Montserrat', sans-serif;
}

.blurb {
  font-size: 12px;
  color: #fff;
  margin-bottom: 30px;
  font-family: 'Montserrat', sans-serif;
  padding-left: 100px;
  padding-right: 100px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

strong {
  letter-spacing: 0;
  font-size: 1.4rem;
  color: #222;
  font-weight: 600;
  list-style-type: none;
  font-family: 'Inter Tight', sans-serif;
  line-height: 1.4;
}

li {
  background-color: #fff;
  grid-row-gap: 1rem;
  border-radius: 1rem;
  cursor: pointer;
  border: 1px solid rgba(0,0,0,.1);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 24px;
  transition: background-color .2s;
  display: flex;
  flex-direction: column;
  margin-left: -34px;
  /* margin-right: 20px; */
}

.bubble-body {
  max-width: 60ch;
  color: #696969;
  letter-spacing: .4px;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 1.125rem;
  line-height: 1.5;
  display: flex;
}

.form-input {
  width: 200px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 5px;
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
  text-align: left;
}

.btn {
  display: block;
  margin: 0 auto;
  padding: 15px 30px;
  font-size: 16px;
  background-color: rgba(0,0,0,.8);
  color: #fff;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  text-decoration: none; /* Remove default underline style for anchor tags */
  width: fit-content; /* Adjust the button width based on content */
  font-family: 'Montserrat', sans-serif;
}

.section-header {
  font-size: 3.7em;
  color: #000;
}

.coming-soon {
  font-size: 22px;
  color: #696969;
  margin-top: -20px;
}

.section-btn {
  margin: 0 auto;
  align-self: flex-start;
  padding: 15px 30px;
  font-size: 18px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  text-decoration: none; /* Remove default underline style for anchor tags */
  width: fit-content; /* Adjust the button width based on content */
  font-family: 'Montserrat', sans-serif;
}

.btn:hover {
  background-color: #45b9d3;
}

.login-btn-container {
  position: absolute; 
  top: 10px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-info {
  color: #000;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {

  /* Reset styles that might cause layout issues */
  body, li {
    margin: 0;
    padding: 0;
  }

  /* Make flex containers stack vertically */
  .container1, .container1b, .container2, .container3,
  .problem-section, .problem-section2, .problem-section3,
  .problem-section4 {
    flex-direction: column;
  }

  /* Adjust font sizes and paddings for headers and text */
  .title, .header, h3 {
    font-size: 24px;
    padding: 10px;
  }

  .subtitle, .h4, p {
    font-size: 16px;
    padding: 10px;
  }

  /* Buttons and form inputs take full width */
  .btn, .form-input, .sign-up-btn, .create-account-btn {
    width: 100%;
  }

  .login-btn-container {
    position: static;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  /* Adjust footer */
  .footer {
    flex-direction: column;
    align-items: flex-start;
  }

  /* Other specific adjustments */
  .content-container, .email-form {
    padding: 10px;
  }
  
  .form {
    width: 100%;
  }
}

