.view-feature {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #fff;
  min-height: 100vh;
  font-family: 'Montserrat', sans-serif;  
  width: 100vw;
  padding-left: 200px;
}

.feature-name {
  font-size: 40px;
  font-family: 'Montserrat', sans-serif;
  word-break: break-word; /* To prevent long text from overflowing */
  margin: 20px 20px;
  color: black;
}

.problem-statement, .feature-input-field {
  background-color: white;
  color: black;
  padding: 15px;
  border-radius: 8px;
  margin: 15px;
  font-family: 'Montserrat', sans-serif;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
}

label {
  margin-left: 15px;
}

.feature-text {
  display: flex;
  justify-content: space-between;
  align-items: center; /* If you want to vertically center the text and the icon */
  width: 100%; /* If you want the container to take up the full width available */
  background-color: white;
}

.editable-field {
  font-size: 1em; /* adjust as needed */
  padding: 0;
  margin: 0;
  border: none; /* removes the default border on inputs */
  outline: none; /* removes the highlight outline on inputs */
  background: transparent; /* makes the input's background transparent */
  width: 90%; /* reduced width to accommodate the icon */
}

.form-columns {
  display: flex;
  justify-content: space-between;
}

.form-column {
  width: 45%; /* You can adjust this value based on your needs */
}

.whats-next {
  background-color: white;
  border: black 1px solid;
  border-radius: 12px;
  padding: 5px;
  margin: 10px;
  min-width: 50%;
  min-height: 70px;
}

.whats-next-section {
  display: flex;
  flex-direction: row;
}

.tab-container {
  display: flex;  /* Using flex to equally distribute space among tabs */
  width: 100%;    /* Making sure it spans the width of the page */
  margin-bottom: 20px;
  padding-right: 15px;
}

.tab {
  flex: 1;                /* Makes each tab take up an equal amount of space in the container */
  background-color: transparent; /* Transparent background */
  color: black;            /* Text color */
  text-align: center;     /* Centering the text in the tab */
  padding: 10px 0;        /* Giving some vertical padding for better appearance */
  cursor: pointer;        /* Changing cursor to pointer to indicate clickable area */
  transition: background-color 0.3s; /* Smooth transition for hover effects */
  border-color: black;
  border: 1px solid black;   /* 1px solid black border on top */
  border-radius: 10px;
  margin-left: 50px;
}

.tab:hover {
  background-color: darkgray; /* Yellow background on hover */
  color: white;
}

.tab.active {
  background-color: darkgray;   /* Blue background for the active tab */
  color: white;             /* Assuming you'd want white text on a blue background, adjust if needed */
}

@media screen and (max-width: 1200px) {
  .view-feature {
    padding-left: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .view-feature {
    padding-left: 1rem;
  }

  .feature-name {
    font-size: 2rem;
    margin: 10px;
  }

  .form-columns {
    flex-direction: column;
  }

  .form-column {
    width: 100%;
  }

  .problem-statement, .feature-input-field {
    margin: 5px;
    padding: 10px;
  }
}
